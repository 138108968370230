<template>
<div class="app-main__inner">
  <div class="row">
    <div class="col-md-12">
        <div class="app-page-title dashboard-title">
            <div class="page-title-wrapper">
                <div class="page-title-heading">
                    <div class="page-title-icon">
                        <i class="pe-7s-note2 icon-gradient bg-happy-itmeo">
                        </i>
                    </div>
                    <div>PTE 90/90</div>
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="row">
      <div class="col-12 pdReader" v-if="activeTab=='PTE90'" >
        <b-overlay :show="showOverLap" >
            <img v-if="showOverLap" src="@/assets/images/PTE90Img.png" style="width: 100%;">
            <!-- <vue-pdf-app :config="config" :title="true" pdf="pdf/PTE90.pdf"></vue-pdf-app> -->
          <template #overlay>
                <div class="text-center">
                <b-icon icon="lock" font-scale="3" animation="cylon"></b-icon>
            
                <h4 class="mt-2" id="cancel-label">
                    For premium users</h4>
                </div>
                </template>
            </b-overlay>
            <vue-pdf-app v-if="!showOverLap" :config="config" :title="true" pdf="pdf/PTE90.pdf"></vue-pdf-app>
      </div>
   
  </div>
</div>
</template>
<script>
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";
export default {
    props:['showOverLap'],
    components: {
    VuePdfApp
    },
    data(){
        return {
            activeTab:'PTE90',
            config: {
                toolbar: {
                    toolbarViewerRight: { 
                        presentationMode: true,
                        openFile: false,
                        print: false,
                        download: false,
                        viewBookmark: true,
                        theme:'light'
                        }
                    }
            },
        }
    },
    methods:{
    }
}
</script>
<style scoped>
.tabs-row .nav-tabs{
    margin: 0 0 30px 0;
}
.pdReader
{
min-height:80vh;
border:1px solid #ccc;
padding: 0px;
}
</style>